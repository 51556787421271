import React, { Component } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';

import { Desktop, Tablet, Mobile } from '../Breakpoints';
import api from '../../utils/api';

const API = new api();

require('./main.css');

export default class AboutTile extends Component {
  state = {
    doc: null,
    data: null
  }

  componentDidMount() {
    API.getDocUid('about').then((res) => {
      this.setState({
        doc: res[0],
        data: res[0].data
      })
    }).catch((err) => {
      console.log(err);
    })
  }

  _renderTile = (size) => {
    const { data } = this.state;

    return (
      <div className="tile tile--about" style={size}>
        {(data && data.background.url) && <div className="tile_background" style={{'backgroundImage': `url(${data.background.url})`}} />}
        {(data && data.logo.url) && <div className="tile_logo icon"><img src={data.logo.url} alt={data.title + ' logo'} /></div>}
        {data && <div className="tile_title tile_title--show">{RichText.render(data.title)}</div>}
        <a className="tile_pageLink" href="/about">About</a>
      </div>
    )
  }



  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <Desktop>{this._renderTile(responsive.desktop)}</Desktop>
        <Tablet>{this._renderTile(responsive.tablet)}</Tablet>
        <Mobile>{this._renderTile(responsive.mobile)}</Mobile>
      </React.Fragment>
    );
  }

}

const responsive = {
  desktop: {
    width: '20vw'
  },
  tablet: {
    width: 'calc((100vw / 3))'
  },
  mobile: {
    width: 'calc((100vw / 3))',
    height: '125px',
    minHeight: 0
  }
};
