import React, { Component } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';

import { Desktop, Tablet, Mobile } from '../Breakpoints';
import api from '../../utils/api';

const API = new api();

export default class Tile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      data: null
    }
  }

  componentDidMount() {
    API.getDocById(this.props.id).then((res) => {
      this.setState({
        doc: res,
        data: res.results[0].data
      })
    }).catch((err) => {
      console.log(err);
    })
  }

  _renderTile = (size, data) => {
    return (
      <div className="tile" style={size}>
        {(data && data.background.url) && <div className="tile_background" style={{'backgroundImage': `url(${data.background.url})`}} />}
        {(data && data.logo.url) && <div className="tile_logo"><img src={data.logo.url} alt={data.title + ' logo'} /></div>}
        {data && <div className="tile_title">{RichText.render(data.title)}</div>}
        {(data && data.url.url) && <a className="tile_link" href={data.url.url} target="_blank">View</a>}
      </div>
    )
  }

  render() {
    const {props} = this;
    const { data } = this.state;

    return (
      <React.Fragment>
        <Desktop>{this._renderTile(responsive.desktop, data)}</Desktop>
        <Tablet>{this._renderTile(responsive.tablet, data)}</Tablet>
        <Mobile>{this._renderTile(responsive.mobile, data)}</Mobile>
      </React.Fragment>
    );
  }
}

const responsive = {
  desktop: {
    width: '20vw'
  },
  tablet: {
    width: '50vw'
  },
  mobile: {
    width: '100vw'
  }
};
