import React, { Component } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';

import api from '../../utils/api';

import { Desktop, Tablet, Mobile } from '../Breakpoints';
import Tile from './Tile';
import AboutTile from '../about/AboutTile';
import ContactTile from '../contact/ContactTile';
import ResumeTile from '../resume/ResumeTile';
import HomeTile from '../home/HomeTile';

const API = new api();

export default class TileWrapper extends Component {
  state = {
    data: null,
    docs: null,
    error: null
  }

  componentDidMount() {
    API.getDocType('tile').then((res) => {
      console.log(res);
      this.setState({
        docs: res.results,
        data: res
      })
    }).catch((err) => {
      this.setState({ error: err })
    })
  }

  componentToRender(item, index) {
    const uid = item.uid;

    switch (uid) {
      case 'about':
        return <AboutTile key={index} />
      case 'contact':
        return <ContactTile key={index} />
      case 'resume':
        return <ResumeTile key={index} />
      case 'home':
        return <HomeTile key={index} />
      default:
        return <Tile key={index} id={item.id} order={index}/>
    }

  }

  render() {
    const { docs } = this.state;

    return (
      <div style={defaultStyles}>
        {docs ? docs.map((item, index) => {
          return this.componentToRender(item, index);
        }) : <p>Loading Data...</p>}
      </div>
    );
  }
}

const defaultStyles = {
  display: 'flex',
  flexWrap: 'wrap'
}
