import React, { Component } from 'react';

import firebase from '../../utils/firebase.js';
const db = firebase.database();

require('./main.css');

export default class Form extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    disabled: 'true',
    messageSent: false,
    showAlert: null,
    showFormValidationError: false
  }

  sendInfoToDatabase = (object) => {
    const dateNow = Date.now();
    db.ref('messages/' + dateNow).set(object);
  }

  handleOnSubmit = (event) => {
    event.preventDefault();
    const contactObject = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message
    };

    this.sendInfoToDatabase(contactObject);

    this.setState({
      messageSent: true,
      showAlert: 'success',
      name: '',
      email: '',
      subject: '',
      message: ''
    }, this.handleSuccessFail());
  }

  handleSuccessFail = () => {
    const that = this;

    setTimeout(function () {
      that.setState({
        showAlert: null
      });
    }, 3000);
  }

  handleOnChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;

    this.setState({ [key]: value }, () => {
      if (
        this.state.name != '' &&
        this.state.message != '' &&
        this.state.email  != '' &&
        this.state.subject != ''
      ) {

        if (this.state.email.split('@').length > 1) {
          console.log('valid');
          this.setState({
            disabled: false,
            showFormValidationError: false
          });
        }

      } else {
        this.setState({
          disabled: true,
          showFormValidationError: true
        });
      }
    });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleOnSubmit} className="form">
          {this.state.showAlert === 'success' ? <span className="form_status form_status--success">Your message was successfully sent. We will get back to you soon.</span> : null}
          {this.state.showFormValidationError ? <span className="form_status form_status--invalid">Please enter a valid name, email, subject and message.</span> : null}
          <div className="form_field">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" value={this.state.name} onChange={this.handleOnChange} placeholder="Name"/>
          </div>
          <div className="form_field">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" value={this.state.email} onChange={this.handleOnChange} placeholder="Email"/>
          </div>
          <div className="form_field">
            <label htmlFor="subject">Subject</label>
            <input type="text" name="subject" id="subject" value={this.state.subject} onChange={this.handleOnChange} placeholder="Subject"/>
          </div>
          <div className="form_field">
            <label htmlFor="message">Message</label>
            <textarea type="text" name="message" id="message" value={this.state.message} onChange={this.handleOnChange} placeholder="Message"/>
          </div>
          <div className="form_field">
            <input className="button" type="submit" id="submit" value="Submit" />
          </div>
        </form>
      </div>
    );
  }
}
