import React, { Component } from 'react';

import { Desktop, Tablet, Mobile } from '../Breakpoints';
import TileWrapper from '../tile/TileWrapper';

require('./main.css');

export default class Home extends Component {
  componentDidMount() {
    console.log('did load');
  }

  render() {
    return (
      <div style={desktopStyles}>
        <TileWrapper />
      </div>
    );
  }
}

const desktopStyles = {
  display: 'flex',
  flexWrap: 'wrap'
}
