import React, { Component } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';

import { Desktop, Tablet, Mobile } from '../Breakpoints';
import api from '../../utils/api';

const API = new api();

require('./main.css');

export default class AboutTile extends Component {
  state = {
    doc: null,
    data: null
  }

  componentDidMount() {
    API.getDocUid('home').then((res) => {
      this.setState({
        doc: res[0],
        data: res[0].data
      })
    }).catch((err) => {
      console.log(err);
    })
  }

  _renderTile = (size) => {
    const { data } = this.state;

    return (
      <div className="tile tile--home" style={size}>
        {(data && data.background.url) && <div className="tile_background" style={{'backgroundImage': `url(${data.background.url})`}} />}
        {(data && data.logo.url) && <div className="tile_logo"><img src={data.logo.url} alt={data.title + ' logo'} /></div>}
        {data && <div className="tile_title tile_title--show"><h3>Jordan Melendez</h3></div>}
        {data && (
          <div style={{zIndex: 1}}>
            <p className="intro">Hey there! I'm Jordan, a developer who takes pride in developing fast, functioning and attractive web applications. Let's talk about building you an awesome website.</p>
            <a href="/contact" className="button button--primary">Let's Talk</a>
          </div>
        )}
        {(data && data.url.url) && <a className="tile_link" href="/">Home</a>}
        <ul className="social">
          <li><a target="_blank" href="https://www.facebook.com/jordan.melendez.372" className="social_link facebook">Facebook</a></li>
          <li><a target="_blank" href="https://www.linkedin.com/in/melendezjordan/" className="social_link linkedin">LinkedIn</a></li>
          <li><a target="_blank" href="https://github.com/jmelendev" className="social_link github">Github</a></li>
        </ul>

      </div>
    )
  }



  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <Desktop>{this._renderTile(responsive.desktop)}</Desktop>
        <Tablet>{this._renderTile(responsive.tablet)}</Tablet>
        <Mobile>{this._renderTile(responsive.mobile)}</Mobile>
      </React.Fragment>
    );
  }

}

const responsive = {
  desktop: {
    width: '40vw'
  },
  tablet: {
    width: '100vw'
  },
  mobile: {
    width: '100vw',
    minHeight: '250px'
  }
};
