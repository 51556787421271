import React, { Component } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';


import { Desktop, Tablet, Mobile } from '../Breakpoints';
import api from '../../utils/api';

const API = new api();

require('./main.css');

export default class About extends Component {
  state = {
    data: null,
    doc: null
  }

  componentDidMount() {
    API.getDocType('about_page').then((res) => {
      console.log(res);
      this.setState({
        doc: res,
        data: res.results[0].data
      })
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    const { data } = this.state;
    return (
      <div className="page page--about">
        <div>
          <a href="/" className="back">Back</a>
          {data && RichText.render(data.body)}
          <a href="/contact" className="button button--primary">Let's Talk</a>
        </div>
      </div>
    );
  }
}
