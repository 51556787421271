import * as firebase from "firebase";

// Initialize Firebase
var config = {
  apiKey: "AIzaSyCCIZd-ZyYk0cfYkovZWTbofBzqz54DAlE",
  authDomain: "jordan-portfolio.firebaseapp.com",
  databaseURL: "https://jordan-portfolio.firebaseio.com",
  projectId: "jordan-portfolio",
  storageBucket: "jordan-portfolio.appspot.com",
  messagingSenderId: "808709558240"
};

firebase.initializeApp(config);

export default firebase;
