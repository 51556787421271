import React, { Component } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';


import { Desktop, Tablet, Mobile } from '../Breakpoints';
import api from '../../utils/api';

const API = new api();

require('./main.css');

export default class About extends Component {
  state = {
    data: null,
    doc: null
  }

  componentDidMount() {
    API.getDocType('resume_page').then((res) => {
      this.setState({
        doc: res,
        data: res.results[0].data
      })
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    const { data } = this.state;
    return (
      <div className="page page--resume">
        <div>
          <h1>Jordan Melendez</h1>
          <a href="/" className="back">Back</a>
          {data && RichText.render(data.body)}
        </div>
      </div>
    );
  }
}
