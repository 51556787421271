import React, { Component } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';

import Form from './Form';
import { Desktop, Tablet, Mobile } from '../Breakpoints';
import api from '../../utils/api';

const API = new api();

require('./main.css');

export default class About extends Component {

  render() {
    return (
      <div className="page page--contact">
        <div>
          <h1>Contact Me</h1>
          <a href="/" className="back">Back</a>
          <p>Let's begin growing your business today! Tell me what you're interested in, whether it's design, development, marketing or all three. I look forward to hearing from you!</p>
          <Form />
        </div>
      </div>
    );
  }
}
